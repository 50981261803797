<template>
  <div>
    <commond-form
      :request="formData"
      :route="'/v1/cliente/update/' + $route.params.uuid"
      :run="run"
      :action="'PUT'"
      @cancel="run = false"
      @success="actualizarCorreo"
      @afterError="$emit('changeTab', 1)"
    >
      <template v-slot:data>
        <v-row>
          <v-col cols="12" md="6" lg="6" xl="6">
            <CommonMultiplesTelefonos ref="commonMultiplesTelefonos" :clienteInfo="clienteInfo"/>
          </v-col>
          <v-col cols="12" md="6" lg="6" xl="6">
            <ListadoCorreos
              :idCliente="formData.id"
              ref="listadoCorreos"
              rolAgente="AGENT"
              :correoPrincipal="formData.correo"
              @correoFavoritoModificado="correoFavoritoModificado"
            ></ListadoCorreos>
          </v-col>
        </v-row>
      </template>
    </commond-form>
    <v-row>
      <v-col cols="12" md="12" lg="6" xl="6"> </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          rounded
          dark
          color="#00a7e4"
          outlined
          @click="$emit('changeTab')"
          style="margin-right: 20px;"
        >
          <span style="padding-right: 11%;">
            Cancelar
          </span>
        </v-btn>
        <v-btn rounded dark class="common-botton" @click="actualizarCorreo">
          <span style="padding-right: 11%;">
            Guardar
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CommondForm from "@/components/commonComponents/CommondForm.vue";
import ListadoCorreos from "@/components/listadoCorreos/ListadoCorreos.vue";
import CommonMultiplesTelefonos from "@/components/commonComponents/telefonos/CommonMultiplesTelefonos.vue";

export default {
  components: {
    CommondForm,
    ListadoCorreos,
    CommonMultiplesTelefonos
  },

  data() {
    return {
      switch_data: 1,
      solicitudes: [],
      filtros: [],
      loading: false,
      run: false,
      inputsRequeridos: [(value) => !!value || "Campo Requerido"],
      formData: {
        id: null,
        status: 0,
        regimen: null,
        nombre: null,
        apellidoPaterno: null,
        apellidoMaterno: null,
        genero: null,
        fechaNacimiento: null,
        estadoCivil: null,
        razonSocial: null,
        fechaConstitucion: null,
        rfc: null,
        correo: null,
        celular: null,
        telefonoOficina: null,
        agenteId: null,
        direccionFacturacionId: null,
        direccionResidenciaId: null,
        fechaCreacion: null,
        creadoPor: false,
        modificadoPor: false,
        telefonoMovil: null,
      },
    };
  },
  props: {
    clienteInfo: Object,
    operadores: Object,
  },
  mounted() {
    this.fillData();
  },
  methods: {
    fillData() {
      var keys = Object.keys(this.formData);
      keys.map((e) => {
        if (typeof this.clienteInfo[e] != "undefined") {
          this.formData[e] = this.clienteInfo[e];
        }
      });
      this.formData.status = this.clienteInfo.status;
    },
    correoFavoritoModificado(v) {
      this.formData.correo = v;
    },
    async actualizarCorreo() {
      await this.$refs.commonMultiplesTelefonos.enviarForm();
      await this.$refs.listadoCorreos.actualizarCorreos();
      this.$emit("getData");
    },
  },
};
</script>
