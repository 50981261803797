<template>
  <div>
    <v-form ref="telefonoForm" lazy-validation>
      <v-row no-gutters v-for="(telefono, idx) in telefonos" :key="idx">
        <v-col cols="8" md="11" lg="11" xl="11" v-if="telefono.enabled">
          <v-text-field
            label="Telefono"
            v-model="telefono.telefono"
            :rules="inputsRequeridos"
            maxlength="10"
            minLength="10"
            outlined
            color="#00a7e4"
            dense
          ></v-text-field>
        </v-col>
        <v-col
          cols="1"
          md="1"
          lg="1"
          xl="1"
          v-if="telefono.enabled && currentActiveTelefonos > 1"
        >
          <v-btn
            tile
            elevation="0"
            color="red"
            block
            style="margin-top: 3px;"
            @click="deletePhone(idx)"
            icon
          >
            <v-icon small>
              mdi-delete-empty
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-col cols="12" v-if="currentActiveTelefonos < 5">
      <v-btn block small tile elevation="0" @click="addTelefono">
        <v-icon left>mdi-plus</v-icon>
        Agregar Teléfono
      </v-btn>
    </v-col>
    <CommonActionModal
      :dialog="dialog"
      :title="'¿Quieres borrar el número de contacto?'"
      :subtitle="mensaje"
      :bottonText="'Si, borralo.'"
      @userChoice="eleccionTelefono"
    />
  </div>
</template>

<script>
import CommonActionModal from "@/components/commonComponents/CommonActionModal.vue";
import { createUpdateTelefonosForCliente } from "@/services/cliente/Clientes.js";

export default {
  props: {
    clienteInfo: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    CommonActionModal,
  },
  data() {
    return {
      inputsRequeridos: [
        (value) => !!value || "Campo Requerido",
        (value) => (value && value.length === 10) || "Debe tener 10 dígitos",
      ],
      telefonos: [],
      mensaje: "",
      dialog: false,
      idxEliminar: null,
      telefonosAux: [],
    };
  },
  computed: {
    currentActiveTelefonos() {
      let resultado = 0;
      this.telefonos.map((e) => {
        if (e.enabled) resultado++;
      });
      return resultado;
    },
  },
  methods: {
    formatTelefonos() {
      const telefonos = this.clienteInfo.phones;
      telefonos.forEach((e) => {
        this.telefonos.push({
          id: e.id,
          telefono: e.telefono,
          enabled: e.enabled,
        });
      });
      this.telefonosAux = JSON.parse(JSON.stringify(this.telefonos));
    },
    addTelefono() {
      if (this.currentActiveTelefonos < 5) {
        this.telefonos.push({ id: null, telefono: "", enabled: true });
      }
    },
    deletePhone(idx) {
      if (this.telefonos[idx].id != null) {
        this.idxEliminar = idx;
        this.mensaje =
          "¿El número de teléfono " +
          this.telefonos[idx].telefono +
          " se eliminara de lista estás seguro?";
        this.dialog = true;
      } else this.telefonos.splice(idx, 1);
    },
    eleccionTelefono(choise) {
      if (choise) this.telefonos[this.idxEliminar].enabled = false;
      this.dialog = false;
      this.idxEliminar = null;
    },
    async enviarForm() {
      if (this.$refs.telefonoForm.validate()) {
        let eliminados = [];
        let nuevos = []

        for(let i=0; i < this.telefonos.length; i++ ){
          if(!this.telefonos[i].enabled && this.telefonos[i].id != null)
            eliminados.push(this.telefonos[i].id)
          if(this.telefonos[i].enabled && this.telefonos[i].id == null)
            nuevos.push(this.telefonos[i].telefono)

          const telefono = this.telefonos[i]
          if(telefono.id != null){
            const telefonoAux = this.telefonosAux.find((e) => e.id == telefono.id)
            if(telefonoAux.telefono != telefono.telefono && telefono.enabled){
              nuevos.push(telefono.telefono)
              eliminados.push(telefono.id)
            }
          }
        }
        try{
          await createUpdateTelefonosForCliente({
            nuevos: nuevos,
            eliminados: eliminados,
            cliente_id: this.clienteInfo.id
          })
          this.$toast.success('Telefonos actualizados con exito ! ')
          location.reload()
        }catch{
          this.$toast.error('Fallo la actualizacion de correos')
          location.reload()
        }
      }
    },
  },

  mounted() {
    this.formatTelefonos();
  },
};
</script>
